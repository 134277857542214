import React from 'react'

//Styles
import { scroll_down } from '../elements/Scrolldown.module.css'

export default function Scrolldown() {

  return (
  
    <svg className={scroll_down} viewBox="0 0 70 70" >
      <circle cx="35" cy="35" r="35"/>
      <polyline points="49.21,31.07 35,45.28 20.79,31.07 	"/>
      <path d="M36.11,1.13 M36.11,1.13C54.29,1.72,68.89,16.69,68.89,35s-14.6,33.28-32.78,33.87"/>
    </svg>

  )
}