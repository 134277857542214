import React, { useRef, useLayoutEffect } from 'react'

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'

//GSAP
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

//Components
import Scrolldown from '../elements/Scrolldown'

//Styles
import { 
  hero, 
  hero_content, 
  hero_image, 
  scroll, 
  pin, 
} from '../hero/Hero.module.css'

//Register Scrolltrigger
gsap.registerPlugin(ScrollTrigger)

export default function Hero(props) {

  //Set GSAP Ref
  let el = useRef();

  // Init useLayoutEffect Hook
  useLayoutEffect(() => {

    //Set GSAP Selector Utility
    const q = gsap.utils.selector(el);

    // Tie GSAP wrapper To Ref
    const wrapper = el.current;

    // Animate in .item-anim elements From 
    gsap.from(q(".item-anim"), { 
      opacity: 0,
      y: 20,
    });

    // Animate .item-anim elements From 
    gsap.fromTo(q(".item-anim"), 
    {
      opacity:1,
      y:0
    },
    // Animate child content elements To + scrollTrigger out
    {
      y: -50,
      opacity: 0,
      stagger: .1,
      scrollTrigger: {
        trigger: wrapper,
        start:'center center',
        scrub: true,
      },
    });

    // Animate in .hero-img-anim elements From 
    gsap.from(q(".hero-img-anim"), { 
      y: -50,
      opacity:0,
      autoAlpha: 0,
      duration:1,

    });

    // Animate .hero-img-anim elements From 
    gsap.fromTo(q(".hero-img-anim"), 
    {    
      y:0,
      opacity:1,
    },

    // Animate .hero-img-anim To + scrollTrigger out
    {
      opacity:.15,
      immediateRender: false,
      overwrite: 'auto',
      scrollTrigger: {
        trigger: wrapper,
        start:'top top',
        pin: wrapper,
        end: "+=400",
        scrub: true,
      },
    });

    // Kill GSAP Animation scrollTrigger
    return () => ScrollTrigger.getAll().forEach(t => t.kill());

  }, [])

  // Set BG Image Props
  let styles = {
    backgroundImage: `url(${props.image})`,
  };

  return (

    <div className={ hero } ref={ el }>
      <div className={`wrapper ${ hero_content }`}>
          <p className='item-anim'><FontAwesomeIcon icon={ faLocationDot } className={ pin } size='1x' /> { props.address }</p>
          <h1 className='item-anim'>{ props.title }</h1>
          <div className={ scroll }>
            <Scrolldown/>
          </div> 
      </div>
      <div className={` hero-img-anim ${hero_image} `} style={ styles }></div>
    </div>

  )
}
