import { useStaticQuery, graphql } from "gatsby";

export const useHomeQuery = () => {
    const data = useStaticQuery(graphql`
    {
      wpPage(id: {eq: "cG9zdDoxOQ=="}) {
        id
        hero {
          text
          image {
            mediaItemUrl
          }
        }
      }
    }
    `)

    return data
}
