import * as React from "react"

//Data Hooks
import { useHomeQuery } from "../hooks/useHomeQuery"
import { useDetailsQuery } from "../hooks/useDetailsQuery"

//Components
import Layout from "../components/layout/Layout"
import Hero from "../components/hero/Hero"
import FullWidthBlock from "../components/content/FullWidthBlock"

export default function IndexPage() {
  //WP Data
  const data = useHomeQuery()
  const details = useDetailsQuery()

  return (
    <Layout>
      <Hero
        title={data.wpPage.hero.text}
        address={details.wp.companyDetails.companyDetails.address}
        image={data.wpPage.hero.image.mediaItemUrl}
      />

      <div id="test" className="wrapper">
        <FullWidthBlock
          title="Want to get away but no time for a holiday?"
          content="A day on a narrowboat means getting away from it all. Leave the stress and strain behind; take things easy whilst you drift along at a few miles an hour through the tranquil countryside."
          linkTo="/boat-hire/"
          linkText="Meet Our Boat"
          fancyBgText="Boat Hire"
        />
      </div>
    </Layout>
  )
}

export function Head() {
  return (
    <>
      <title>Home | Lower Park Marina</title>
      <meta
        name="description"
        content="Welcome to the Lower Park Marina website."
      />
    </>
  )
}
